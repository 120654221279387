import { useState } from 'react'

import { shareStatus } from '../../lib/share'
import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
  solution: string
  guesses: string[]
  isGameLost: boolean
  handleShareToClipboard: () => void
  handleShareFailure: () => void
  isHardMode: boolean
  isDarkMode: boolean
  isHighContrastMode: boolean
}

export const ShareModal = ({
  isOpen,
  handleClose,
  solution,
  guesses,
  isGameLost,
  isHardMode,
  isDarkMode,
  isHighContrastMode,
  handleShareToClipboard,
  handleShareFailure,
}: Props) => {
  return (
    <BaseModal
      title="Thanks for playing"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Share the game with your friends and enjoy your word deal
      </p>

      <button
        type="button"
        onClick={() => {
          shareStatus(
            solution,
            guesses,
            isGameLost,
            isHardMode,
            isDarkMode,
            isHighContrastMode,
            handleShareToClipboard,
            handleShareFailure
          )
        }}
        className={`mx-auto mt-4 flex w-[320px] cursor-pointer items-center justify-center rounded-md bg-pink-400 p-2.5 text-white transition-all duration-150 ease-in hover:bg-pink-500`}
      >
        Share
      </button>
    </BaseModal>
  )
}
