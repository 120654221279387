import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { AlertContainer } from './components/alerts/AlertContainer'
import { ShareModal } from './components/modals/ShareModal'
import { LONG_ALERT_TIME_MS } from './constants/settings'
import { GAME_COPIED_MESSAGE, SHARE_FAILURE_TEXT } from './constants/strings'
import { useAlert } from './context/AlertContext'

export default function Deals() {
  const [email, setEmail] = useState('')
  const [loading, setloading] = useState(false)
  const [isOpen, setisOpen] = useState(false)

  const handleClose = () => {
    setisOpen(false)
  }

  const { state } = useLocation()

  const { showSuccess, showError } = useAlert()

  const onSubmit = (e: any) => {
    e.preventDefault()
    if (!email) {
      return showError('Please enter your email address', {
        durationMs: 1500,
      })
    }

    setloading(true)
    const formData = new FormData()

    // Hidden field key/values.
    formData.append('u', '1')
    formData.append('f', '1')
    formData.append('s', 's')
    formData.append('c', '0')
    formData.append('m', '0')
    formData.append('act', 'sub')
    formData.append('v', '2')
    formData.append('or', '2b9023f925cdc2ddcb2b00dd94242d98')

    // Form field key/values.
    //formData.append('fullname', 'Test User')
    formData.append('email', email)

    // Pass FormData values into a POST request to ActiveCampaign.
    // Mark form submission successful, otherwise return error state.
    fetch('https://wordeals.activehosted.com/proc.php', {
      method: 'POST',
      body: formData,
      mode: 'no-cors',
    })
      .then((response) => {
        console.log(response.json(), 'response')
        console.log('Success')
        setloading(false)
        setEmail('')
        setisOpen(true)
      })
      .catch((err) => {
        console.log(err)
        setloading(false)
      })
  }

  return (
    <div className="flex min-h-[100vh] flex-col items-center justify-center bg-white dark:bg-gray-800">
      <AlertContainer />
      <ShareModal
        isOpen={isOpen}
        handleClose={handleClose}
        guesses={state?.guesses}
        isDarkMode={state?.isDarkMode}
        isGameLost={state?.isGameLost}
        isHardMode={state?.isHardMode}
        isHighContrastMode={state?.isHighContrastMode}
        solution={state?.solution}
        handleShareToClipboard={() => showSuccess(GAME_COPIED_MESSAGE)}
        handleShareFailure={() =>
          showError(SHARE_FAILURE_TEXT, {
            durationMs: LONG_ALERT_TIME_MS,
          })
        }
      />

      <h2 className="text-center text-[24px] text-[#272727] opacity-90 dark:text-white">
        You just earned a new WorDeal!
      </h2>
      <p className="my-2 text-[18px] text-[#272727] opacity-70 dark:text-white">
        Drop your best email below.
      </p>
      <form onSubmit={onSubmit}>
        <input
          type={'email'}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          placeholder="Enter your email here"
          className="mt-4 block w-[320px] rounded-md border-[1.5px] border-gray-200 px-4 py-2.5 outline-none outline-transparent focus:outline-none focus:ring-0"
        />
        <button
          type="submit"
          disabled={loading}
          className={`mx-auto mt-4 flex w-[320px] cursor-pointer items-center justify-center rounded-md bg-pink-400 p-2.5 text-white transition-all duration-150 ease-in hover:bg-pink-500 ${
            loading ? 'opacity-75' : 'opacity-100'
          }`}
        >
          {loading ? <div className="basic" /> : 'Get WorDeal Now'}
        </button>
      </form>
    </div>
  )
}
